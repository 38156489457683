// RfqDetail.js
import DeleteIcon from "@mui/icons-material/Delete";
import GetAppIcon from "@mui/icons-material/GetApp";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InfoIcon from "@mui/icons-material/Info";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  acceptOffer,
  acceptOfferForManager,
  assignFactoryToRfq,
  deleteRfqFile,
  getAllFactories,
  getOfferFiles,
  getRfq,
  requestRequotation,
  reviseOffer,
  sendToManager,
  updateRfqNote,
  updateSalesPrices,
  uploadFile,
  getOfferHistory,
} from "../../Api/Rfq";
import Loading from "../../components/global/Loading";
import { useNotification } from "../../context/NotificationContext";
import { getUserCredentials } from "../../utils/getUserCredentials";
import FactoryUserInput from "./FactoryUserInput"; // Yeni bileşeni içe aktarın
import FactoryUserNote from "./FactoryUserNote";
import OfferEditDrawer from "./OfferEditDrawer";
import RfqInfoCard from "./RfqInfoCard";
import { acceptAllOffers, unAcceptOffer } from "../../Api/Rfq";

const TitleBox = ({ title, status }) => (
  <Box
    sx={{
      backgroundColor: "#ef7757",
      width: "100%",
      padding: "10px 20px",
      display: "flex",
      pt: 2,
      borderRadius: "5px",
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <Typography
      gutterBottom
      sx={{
        fontWeight: "600",
        fontSize: "1.2rem",
        color: "white!important",
      }}
    >
      {title}
    </Typography>
    {status === "waiting for manager approval" && (
      <Chip
        label="Waiting for Manager Approval"
        sx={{ ml: 2, color: "white!important" }}
      />
    )}
  </Box>
);

const calculatePanelArea = (dimensions, quantity) => {
  if (
    !dimensions ||
    !dimensions.panelSizeX ||
    !dimensions.panelSizeY ||
    !dimensions.panelX ||
    !dimensions.panelY
  ) {
    return "Invalid dimensions";
  }

  const panelSizeX = Number(dimensions.panelSizeX);
  const panelSizeY = Number(dimensions.panelSizeY);
  const panelX = Number(dimensions.panelX);
  const panelY = Number(dimensions.panelY);

  const area =
    ((panelSizeX * panelSizeY) / (panelX * panelY) / 1000000) * quantity;

  return area.toFixed(2);
};

const getLowestTotalByQuantity = (factories) => {
  const lowestTotals = {};

  factories.forEach((factory) => {
    // Normal fiyatları kontrol et
    Object.entries(factory.prices || {}).forEach(([quantity, priceDetails]) => {
      const totalPrice =
        priceDetails.unit * quantity +
        priceDetails.tooling +
        priceDetails.etest;

      // Eğer bu quantity için bir lowestTotal yoksa veya daha düşük bir total bulunursa
      if (
        !lowestTotals[quantity] ||
        totalPrice < lowestTotals[quantity].total
      ) {
        lowestTotals[quantity] = {
          total: totalPrice,
          factoryId: factory.id, // Bu factory'yi referans al
          type: "normal", // Normal fiyat olduğunu belirt
        };
      }
    });

    // // Express fiyatları kontrol et
    Object.entries(factory.expressPrices || {}).forEach(
      ([quantity, expPriceDetails]) => {
        const totalExpressPrice =
          expPriceDetails.expUnit * quantity +
          expPriceDetails.expTooling +
          expPriceDetails.expEtest;

        // Eğer bu quantity için express toplamı daha düşükse
        if (
          !lowestTotals[quantity] ||
          totalExpressPrice < lowestTotals[quantity].total
        ) {
          lowestTotals[quantity] = {
            total: totalExpressPrice,
            factoryId: factory.id, // Bu factory'yi referans al
            type: "normal", // Express fiyat olduğunu belirt
          };
        }
      }
    );
  });

  return lowestTotals;
};

const RfqDetail = () => {
  const navigate = useNavigate();
  const userRole = getUserCredentials().role;
  const userId = getUserCredentials().id;
  const { id } = useParams();
  const [factories, setFactories] = useState([]);
  const [selectedFactories, setSelectedFactories] = useState([]);
  const [expDays, setExpDays] = useState({});
  const [rfqDetail, setRfqDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const { showNotification } = useNotification();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [rfqFiles, setRfqFiles] = useState([]);
  const [offerFiles, setOfferFiles] = useState([]);
  const [editingOffer, setEditingOffer] = useState({
    offerId: null,
    values: {}, // Başlangıçta boş bir nesne
    expressValues: {}, // Başlangıçta boş bir nesne
    notes: "",
  });

  const [unitPrice, setUnitPrice] = useState({});
  const [tooling, setTooling] = useState({});
  const [etest, setEtest] = useState({});
  const [totalPrice, setTotalPrice] = useState({});
  const [m2Price, setM2Price] = useState({});
  const [expUnitPrice, setExpUnitPrice] = useState({});
  const [expTooling, setExpTooling] = useState({});
  const [expEtest, setExpEtest] = useState({});
  const [expTotalPrice, setExpTotalPrice] = useState({});
  const [expM2Price, setExpM2Price] = useState({});
  const [salesOfferHistory, setSalesOfferHistory] = useState({});

  const [salesPricesState, setSalesPricesState] = useState({
    unitPrice: {},
    tooling: {},
    etest: {},
    totalPrice: {},
    m2Price: {},
    expUnitPrice: {},
    expTooling: {},
    expEtest: {},
    expTotalPrice: {},
    expM2Price: {},
  });

  const [openDialog, setOpenDialog] = useState(false); // Dialog state
  const [selectedOffer, setSelectedOffer] = useState(null); // Seçilen offer
  const [selectedFactoryName, setSelectedFactoryName] = useState("");
  const handleDialogOpen = (offer, factoryName) => {
    setSelectedOffer(offer); // Seçilen teklifi kaydet
    setSelectedFactoryName(factoryName); // Seçilen fabrikanın adını kaydet
    setOpenDialog(true); // Dialogu aç
  };

  const handleDialogClose = () => {
    setOpenDialog(false); // Dialogu kapat
  };

  const handleConfirm = async () => {
    if (selectedOffer) {
      await acceptForManagerFunc(rfqDetail._id, selectedOffer._id);
      setOpenDialog(false); // İşlem tamamlandıktan sonra dialogu kapat
    }
  };

  const calculateTotalAndM2PriceClosed = (
    quantity,
    unit,
    tool,
    test,
    isExpress = false
  ) => {
    const pieces = Number(quantity);

    if (!unit || !pieces || !tool || !test) return;

    const total = unit * pieces + tool + test;
    const area = calculatePanelArea(rfqDetail.dimensions, pieces);

    if (!area) return;

    const m2PriceCalculated = ((total - tool - test) / area).toFixed(4);

    if (isExpress) {
      setSalesPricesState((prev) => ({
        ...prev,
        expTotalPrice: { ...prev.expTotalPrice, [quantity]: total.toFixed(4) },
        expM2Price: { ...prev.expM2Price, [quantity]: m2PriceCalculated },
      }));
    } else {
      setSalesPricesState((prev) => ({
        ...prev,
        totalPrice: { ...prev.totalPrice, [quantity]: total.toFixed(4) },
        m2Price: { ...prev.m2Price, [quantity]: m2PriceCalculated },
      }));
    }
  };

  const calculateUnitAndM2PriceFromTotalClosed = (
    quantity,
    total,
    tool,
    test,
    isExpress = false
  ) => {
    const pieces = Number(quantity);
    const unitCalculated = (total - tool - test) / pieces;
    const area = calculatePanelArea(rfqDetail.dimensions, pieces);
    const m2PriceCalculated = (total - tool - test) / area;

    if (isExpress) {
      setSalesPricesState((prev) => ({
        ...prev,
        expUnitPrice: {
          ...prev.expUnitPrice,
          [quantity]: unitCalculated.toFixed(4),
        },
        expM2Price: {
          ...prev.expM2Price,
          [quantity]: m2PriceCalculated.toFixed(4),
        },
      }));
    } else {
      setSalesPricesState((prev) => ({
        ...prev,
        unitPrice: { ...prev.unitPrice, [quantity]: unitCalculated.toFixed(4) },
        m2Price: { ...prev.m2Price, [quantity]: m2PriceCalculated.toFixed(4) },
      }));
    }
  };

  const calculateUnitAndTotalPriceFromM2PriceClosed = (
    quantity,
    m2Price,
    tool,
    test,
    isExpress = false
  ) => {
    const pieces = Number(quantity);
    const area = calculatePanelArea(rfqDetail.dimensions, pieces);
    const totalCalculated = m2Price * area + tool + test;
    const unitCalculated = (m2Price * area) / pieces;

    if (isExpress) {
      setSalesPricesState((prev) => ({
        ...prev,
        expTotalPrice: {
          ...prev.expTotalPrice,
          [quantity]: totalCalculated.toFixed(4),
        },
        expUnitPrice: {
          ...prev.expUnitPrice,
          [quantity]: unitCalculated.toFixed(4),
        },
      }));
    } else {
      setSalesPricesState((prev) => ({
        ...prev,
        totalPrice: {
          ...prev.totalPrice,
          [quantity]: totalCalculated.toFixed(4),
        },
        unitPrice: { ...prev.unitPrice, [quantity]: unitCalculated.toFixed(4) },
      }));
    }
  };

  const handleInputChange = (e, quantity, field, isExpress = false) => {
    const value = Number(e.target.value);

    if (isExpress) {
      switch (field) {
        case "expUnitPrice":
          setExpUnitPrice((prev) => ({ ...prev, [quantity]: value }));
          calculateTotalAndM2Price(
            quantity,
            value,
            expTooling[quantity],
            expEtest[quantity],
            true
          );
          break;
        case "expTooling":
          setExpTooling((prev) => ({ ...prev, [quantity]: value }));
          calculateTotalAndM2Price(
            quantity,
            expUnitPrice[quantity],
            value,
            expEtest[quantity],
            true
          );
          break;
        case "expEtest":
          setExpEtest((prev) => ({ ...prev, [quantity]: value }));
          calculateTotalAndM2Price(
            quantity,
            expUnitPrice[quantity],
            expTooling[quantity],
            value,
            true
          );
          break;
        case "expTotalPrice":
          setExpTotalPrice((prev) => ({ ...prev, [quantity]: value }));
          calculateUnitAndM2PriceFromTotal(
            quantity,
            value,
            expTooling[quantity],
            expEtest[quantity],
            true
          );
          break;
        case "expM2Price":
          setExpM2Price((prev) => ({ ...prev, [quantity]: value }));
          calculateUnitAndTotalPriceFromM2Price(
            quantity,
            value,
            expTooling[quantity],
            expEtest[quantity],
            true
          );
          break;
        default:
          break;
      }
    } else {
      switch (field) {
        case "unitPrice":
          setUnitPrice((prev) => ({ ...prev, [quantity]: value }));
          calculateTotalAndM2Price(
            quantity,
            value,
            tooling[quantity],
            etest[quantity]
          );
          break;
        case "tooling":
          setTooling((prev) => ({ ...prev, [quantity]: value }));
          calculateTotalAndM2Price(
            quantity,
            unitPrice[quantity],
            value,
            etest[quantity]
          );
          break;
        case "etest":
          setEtest((prev) => ({ ...prev, [quantity]: value }));
          calculateTotalAndM2Price(
            quantity,
            unitPrice[quantity],
            tooling[quantity],
            value
          );
          break;
        case "totalPrice":
          setTotalPrice((prev) => ({ ...prev, [quantity]: value }));
          calculateUnitAndM2PriceFromTotal(
            quantity,
            value,
            tooling[quantity],
            etest[quantity]
          );
          break;
        case "m2Price":
          setM2Price((prev) => ({ ...prev, [quantity]: value }));
          calculateUnitAndTotalPriceFromM2Price(
            quantity,
            value,
            tooling[quantity],
            etest[quantity]
          );
          break;
        default:
          break;
      }
    }
  };

  const handleInputChangeClosed = (e, quantity, field, isExpress = false) => {
    const value = Number(e.target.value);

    if (isExpress) {
      switch (field) {
        case "expUnitPrice":
          setSalesPricesState((prev) => ({
            ...prev,
            expUnitPrice: { ...prev.expUnitPrice, [quantity]: value },
          }));
          calculateTotalAndM2PriceClosed(
            quantity,
            value,
            salesPricesState.expTooling[quantity],
            salesPricesState.expEtest[quantity],
            true
          );
          break;
        case "expTooling":
          setSalesPricesState((prev) => ({
            ...prev,
            expTooling: { ...prev.expTooling, [quantity]: value },
          }));
          calculateTotalAndM2PriceClosed(
            quantity,
            salesPricesState.expUnitPrice[quantity],
            value,
            salesPricesState.expEtest[quantity],
            true
          );
          break;
        case "expEtest":
          setSalesPricesState((prev) => ({
            ...prev,
            expEtest: { ...prev.expEtest, [quantity]: value },
          }));
          calculateTotalAndM2PriceClosed(
            quantity,
            salesPricesState.expUnitPrice[quantity],
            salesPricesState.expTooling[quantity],
            value,
            true
          );
          break;
        case "expTotalPrice":
          setSalesPricesState((prev) => ({
            ...prev,
            expTotalPrice: { ...prev.expTotalPrice, [quantity]: value },
          }));
          calculateUnitAndM2PriceFromTotalClosed(
            quantity,
            value,
            salesPricesState.expTooling[quantity],
            salesPricesState.expEtest[quantity],
            true
          );
          break;
        case "expM2Price":
          setSalesPricesState((prev) => ({
            ...prev,
            expM2Price: { ...prev.expM2Price, [quantity]: value },
          }));
          calculateUnitAndTotalPriceFromM2PriceClosed(
            quantity,
            value,
            salesPricesState.expTooling[quantity],
            salesPricesState.expEtest[quantity],
            true
          );
          break;
        default:
          break;
      }
    } else {
      switch (field) {
        case "unitPrice":
          setSalesPricesState((prev) => ({
            ...prev,
            unitPrice: { ...prev.unitPrice, [quantity]: value },
          }));
          calculateTotalAndM2PriceClosed(
            quantity,
            value,
            salesPricesState.tooling[quantity],
            salesPricesState.etest[quantity]
          );
          break;
        case "tooling":
          setSalesPricesState((prev) => ({
            ...prev,
            tooling: { ...prev.tooling, [quantity]: value },
          }));
          calculateTotalAndM2PriceClosed(
            quantity,
            salesPricesState.unitPrice[quantity],
            value,
            salesPricesState.etest[quantity]
          );
          break;
        case "etest":
          setSalesPricesState((prev) => ({
            ...prev,
            etest: { ...prev.etest, [quantity]: value },
          }));
          calculateTotalAndM2PriceClosed(
            quantity,
            salesPricesState.unitPrice[quantity],
            salesPricesState.tooling[quantity],
            value
          );
          break;
        case "totalPrice":
          setSalesPricesState((prev) => ({
            ...prev,
            totalPrice: { ...prev.totalPrice, [quantity]: value },
          }));
          calculateUnitAndM2PriceFromTotalClosed(
            quantity,
            value,
            salesPricesState.tooling[quantity],
            salesPricesState.etest[quantity]
          );
          break;
        case "m2Price":
          setSalesPricesState((prev) => ({
            ...prev,
            m2Price: { ...prev.m2Price, [quantity]: value },
          }));
          calculateUnitAndTotalPriceFromM2PriceClosed(
            quantity,
            value,
            salesPricesState.tooling[quantity],
            salesPricesState.etest[quantity]
          );
          break;
        default:
          break;
      }
    }
  };

  const handleSubmitSalesPrices = async () => {
    try {
      const dynamicQuantities = Object.keys(unitPrice);

      const prices = {};
      const expressPrices = {};

      dynamicQuantities.forEach((quantity) => {
        prices[quantity] = {
          lt: rfqDetail.lt || 0,
          unit: unitPrice[quantity] || 0,
          tooling: tooling[quantity] || 0,
          etest: etest[quantity] || 0,
          totalPrice: Number(totalPrice[quantity] || 0),
          m2Price: Number(m2Price[quantity] || 0),
        };

        expressPrices[quantity] = {
          expLt: rfqDetail.expLt || 0,
          expUnit: expUnitPrice[quantity] || 0,
          expTooling: expTooling[quantity] || 0,
          expEtest: expEtest[quantity] || 0,
          expTotalPrice: Number(expTotalPrice[quantity] || 0),
          expM2Price: Number(expM2Price[quantity] || 0),
        };
      });

      console.log("Frontend'den Gönderilen Veri:", { prices, expressPrices });

      // Fazladan iç içe prices ve expressPrices olmadan gönderiyoruz
      await updateSalesPrices(id, {
        prices, // Doğrudan prices
        expressPrices, // Doğrudan expressPrices
      });

      navigate("/");

      showNotification("Sales prices saved successfully!", "success");
    } catch (error) {
      showNotification("Error saving sales prices!", "error");
      console.error("Error saving sales prices:", error);
    }
  };

  console.log("RFQ Detail:", rfqDetail);

  const handleSubmitSalesPricesClosed = async () => {
    try {
      const dynamicQuantities = Object.keys(salesPricesState.unitPrice);

      const prices = {};
      const expressPrices = {};

      dynamicQuantities.forEach((quantity) => {
        prices[quantity] = {
          lt: salesPricesState.lt || 0, // Kapalı RFQ için farklı olabilir
          unit: salesPricesState.unitPrice[quantity] || 0,
          tooling: salesPricesState.tooling[quantity] || 0,
          etest: salesPricesState.etest[quantity] || 0,
          totalPrice: Number(salesPricesState.totalPrice[quantity] || 0),
          m2Price: Number(salesPricesState.m2Price[quantity] || 0),
        };

        expressPrices[quantity] = {
          expLt: salesPricesState.expLt || 0, // Kapalı RFQ için farklı olabilir
          expUnit: salesPricesState.expUnitPrice[quantity] || 0,
          expTooling: salesPricesState.expTooling[quantity] || 0,
          expEtest: salesPricesState.expEtest[quantity] || 0,
          expTotalPrice: Number(salesPricesState.expTotalPrice[quantity] || 0),
          expM2Price: Number(salesPricesState.expM2Price[quantity] || 0),
        };
      });

      console.log("Kapalı RFQ için gönderilen veri:", {
        prices,
        expressPrices,
      });

      await updateSalesPrices(id, {
        prices,
        expressPrices,
      });

      navigate("/");
      showNotification(
        "Kapalı RFQ için satış fiyatları başarıyla kaydedildi!",
        "success"
      );
    } catch (error) {
      showNotification(
        "Kapalı RFQ için satış fiyatlarını kaydederken hata oluştu!",
        "error"
      );
      console.error("Error saving sales prices for closed RFQ:", error);
    }
  };

  // Hesaplama Fonksiyonları
  const calculateTotalAndM2Price = (
    quantity,
    unit,
    tool,
    test,
    isExpress = false
  ) => {
    const pieces = Number(quantity);

    // Eğer unit, tool veya test değerleri boşsa hesaplama yapma
    if (!unit || !pieces || !tool || !test) return;

    const total = unit * pieces + tool + test;
    const area = calculatePanelArea(rfqDetail.dimensions, pieces); // M² alanını hesapla

    // Eğer alan 0 veya tanımsızsa, M² Price hesaplama
    if (!area) return;

    const m2PriceCalculated = ((total - tool - test) / area).toFixed(4); // M² Price

    if (isExpress) {
      setExpTotalPrice((prev) => ({ ...prev, [quantity]: total.toFixed(4) }));
      setExpM2Price((prev) => ({ ...prev, [quantity]: m2PriceCalculated }));
    } else {
      setTotalPrice((prev) => ({ ...prev, [quantity]: total.toFixed(4) }));
      setM2Price((prev) => ({ ...prev, [quantity]: m2PriceCalculated }));
    }
  };

  const calculateUnitAndM2PriceFromTotal = (
    quantity,
    total,
    tool,
    test,
    isExpress = false
  ) => {
    const pieces = Number(quantity);
    const unitCalculated = (total - tool - test) / pieces;
    const area = calculatePanelArea(rfqDetail.dimensions, pieces); // M² alanını hesapla
    const m2PriceCalculated = (total - tool - test) / area;

    if (isExpress) {
      setExpUnitPrice((prev) => ({
        ...prev,
        [quantity]: unitCalculated.toFixed(4),
      }));
      setExpM2Price((prev) => ({
        ...prev,
        [quantity]: m2PriceCalculated.toFixed(4),
      }));
    } else {
      setUnitPrice((prev) => ({
        ...prev,
        [quantity]: unitCalculated.toFixed(4),
      }));
      setM2Price((prev) => ({
        ...prev,
        [quantity]: m2PriceCalculated.toFixed(4),
      }));
    }
  };

  const calculateUnitAndTotalPriceFromM2Price = (
    quantity,
    m2Price,
    tool,
    test,
    isExpress = false
  ) => {
    const pieces = Number(quantity);
    const area = calculatePanelArea(rfqDetail.dimensions, pieces); // M² alanını hesapla
    const totalCalculated = m2Price * area + tool + test;
    const unitCalculated = (m2Price * area) / pieces;

    if (isExpress) {
      setExpTotalPrice((prev) => ({
        ...prev,
        [quantity]: totalCalculated.toFixed(4),
      }));
      setExpUnitPrice((prev) => ({
        ...prev,
        [quantity]: unitCalculated.toFixed(4),
      }));
    } else {
      setTotalPrice((prev) => ({
        ...prev,
        [quantity]: totalCalculated.toFixed(4),
      }));
      setUnitPrice((prev) => ({
        ...prev,
        [quantity]: unitCalculated.toFixed(4),
      }));
    }
  };

  const [expressSelections, setExpressSelections] = useState({});

  const handleExpressChange = (factoryId, isChecked) => {
    setExpressSelections((prev) => ({ ...prev, [factoryId]: isChecked }));
  };

  // Dosya indirme fonksiyonu
  const handleFileDownload = (filePath) => {
    // Yeni bir anchor (a) elementi oluşturuyoruz
    const link = document.createElement("a");
    link.href = filePath;
    link.download = filePath.substring(filePath.lastIndexOf("/") + 1);

    // Linki tıklatarak indirme işlemini başlatıyoruz
    document.body.appendChild(link);
    link.click();

    // Linki kaldırıyoruz
    document.body.removeChild(link);
  };

  const getFileIcon = (filename) => {
    const extension = filename.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return <PictureAsPdfIcon />;
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return <ImageIcon />;
      default:
        return <InsertDriveFileIcon />;
    }
  };

  // Yeni değişkenler
  const offers = rfqDetail.offers || [];

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Gizli inputu tetikle
  };

  useEffect(() => {
    const fetchData = async () => {
      if (userRole !== "factoryUser") {
        await fetchFactories();
      }
      await fetchRfqDetail();
      await fetchRfqFiles();
      setLoading(false); // Yalnızca tüm veri yüklendikten sonra false yap
    };
    fetchData();
  }, [userRole, id]);

  useEffect(() => {
    if (
      rfqDetail.managerAcceptance &&
      rfqDetail.managerAcceptance.status === true &&
      rfqDetail.generalInfo &&
      rfqDetail.generalInfo.status === "closed"
    ) {
      const salesPrices = rfqDetail.sales?.salesPrices?.[0] || {};
      const prices = salesPrices.prices || {};
      const expressPrices = salesPrices.expressPrices || {};

      const newState = {
        unitPrice: {},
        tooling: {},
        etest: {},
        totalPrice: {},
        m2Price: {},
        expUnitPrice: {},
        expTooling: {},
        expEtest: {},
        expTotalPrice: {},
        expM2Price: {},
      };

      // Normal fiyatları doldur
      Object.entries(prices).forEach(([quantity, priceData]) => {
        newState.unitPrice[quantity] = priceData.unit || 0;
        newState.tooling[quantity] = priceData.tooling || 0;
        newState.etest[quantity] = priceData.etest || 0;
        newState.totalPrice[quantity] = priceData.totalPrice || 0;
        newState.m2Price[quantity] = priceData.m2Price || 0;
      });

      // Express fiyatları doldur
      Object.entries(expressPrices).forEach(([quantity, expPriceData]) => {
        newState.expUnitPrice[quantity] = expPriceData.expUnit || 0;
        newState.expTooling[quantity] = expPriceData.expTooling || 0;
        newState.expEtest[quantity] = expPriceData.expEtest || 0;
        newState.expTotalPrice[quantity] = expPriceData.expTotalPrice || 0;
        newState.expM2Price[quantity] = expPriceData.expM2Price || 0;
      });

      setSalesPricesState(newState);
    }
  }, [rfqDetail]);

  const handleInputChangeForClosedStatus = (
    e,
    quantity,
    field,
    isExpress = false
  ) => {
    const value = Number(e.target.value);

    if (isExpress) {
      setSalesPricesState((prev) => ({
        ...prev,
        expUnitPrice: {
          ...prev.expUnitPrice,
          [quantity]:
            field === "expUnitPrice" ? value : prev.expUnitPrice[quantity],
        },
        expTooling: {
          ...prev.expTooling,
          [quantity]:
            field === "expTooling" ? value : prev.expTooling[quantity],
        },
        expEtest: {
          ...prev.expEtest,
          [quantity]: field === "expEtest" ? value : prev.expEtest[quantity],
        },
        expTotalPrice: {
          ...prev.expTotalPrice,
          [quantity]:
            field === "expTotalPrice" ? value : prev.expTotalPrice[quantity],
        },
        expM2Price: {
          ...prev.expM2Price,
          [quantity]:
            field === "expM2Price" ? value : prev.expM2Price[quantity],
        },
      }));
    } else {
      setSalesPricesState((prev) => ({
        ...prev,
        unitPrice: {
          ...prev.unitPrice,
          [quantity]: field === "unitPrice" ? value : prev.unitPrice[quantity],
        },
        tooling: {
          ...prev.tooling,
          [quantity]: field === "tooling" ? value : prev.tooling[quantity],
        },
        etest: {
          ...prev.etest,
          [quantity]: field === "etest" ? value : prev.etest[quantity],
        },
        totalPrice: {
          ...prev.totalPrice,
          [quantity]:
            field === "totalPrice" ? value : prev.totalPrice[quantity],
        },
        m2Price: {
          ...prev.m2Price,
          [quantity]: field === "m2Price" ? value : prev.m2Price[quantity],
        },
      }));
    }
  };

  const fetchRfqFiles = async () => {
    try {
      const response = await getOfferFiles(id);
      setRfqFiles(response.data.rfqFiles);
      setOfferFiles(response.data.offerFiles);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFactories = async () => {
    try {
      const response = await getAllFactories();
      setFactories(response.data);
    } catch (error) {
      console.error("Error fetching factories:", error);
    }
  };

  const fetchRfqDetail = async () => {
    try {
      const response = await getRfq(id);
      setRfqDetail(response.data);

      // Load sales offer history for each offer when page loads
      if (response.data.offers && response.data.offers.length > 0) {
        for (const offer of response.data.offers) {
          await loadSalesOfferHistory(offer);
        }
      }
    } catch (error) {
      console.error("Error fetching RFQ details:", error);
    }
  };

  const handleValueChange = (quantity, field, value, isExpress = false) => {
    setEditingOffer((prev) => {
      const updatedValues = {
        ...prev.values,
        [quantity]: {
          ...prev.values[quantity],
          [field]: value,
        },
      };

      if (isExpress) {
        return {
          ...prev,
          expressValues: {
            ...prev.expressValues,
            [quantity]: {
              ...(prev.expressValues[quantity] || {}), // Varsayılan olarak boş bir nesne ile başlat
              [field]: value,
            },
          },
        };
      }

      return {
        ...prev,
        values: updatedValues,
      };
    });
  };

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files); // Seçilen dosyaları state'e kaydediyoruz
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = Array.from(selectedFiles);
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  // Dosyaları yükleme işlemini gerçekleştiren fonksiyon
  // const handleFileUpload = async () => {
  //   if (!selectedFiles || selectedFiles.length === 0) {
  //     showNotification('Please select at least one file to upload', 'warning');
  //     return;
  //   }

  //   setLoading(true); // Yükleme işlemi başladığında loading state'ini true yap

  //   const formData = new FormData();
  //   Array.from(selectedFiles).forEach(file => {
  //     formData.append('files', file); // Her bir dosyayı "files" olarak FormData'ya ekliyoruz
  //   });

  //   try {
  //     // Backend API'ye uygun şekilde dosyayı yükle
  //     await uploadFile(id, userId, formData, userRole);
  //     showNotification('Files uploaded successfully!', 'success');
  //     setSelectedFiles(null); // Dosyaları sıfırla
  //   } catch (error) {
  //     console.error('File upload error:', error.message);
  //     showNotification('An error occurred while uploading the files', 'error');
  //   } finally {
  //     setLoading(false); // Yükleme işlemi bittiğinde loading state'ini false yap
  //     fetchRfqFiles(); // RFQ detaylarını güncellemek için fonksiyonu çağırıyoruz
  //   }
  // };

  const handleFileUpload = async () => {
    if (!selectedFiles || selectedFiles.length === 0) {
      showNotification("Please select at least one file to upload", "warning");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    Array.from(selectedFiles).forEach((file) => {
      formData.append("files", file);
    });

    // Debug logları
    console.log("Upload attempt with:", {
      rfqId: id,
      userId,
      userRole,
      filesCount: selectedFiles.length,
      formDataEntries: Array.from(formData.entries()),
    });

    try {
      const response = await uploadFile(id, userId, formData, userRole);
      console.log("Upload response:", response);
      showNotification("Files uploaded successfully!", "success");
      setSelectedFiles(null);
    } catch (error) {
      console.error("Detailed upload error:", {
        message: error.message,
        stack: error.stack,
        status: error.response?.status,
        responseData: error.response?.data,
      });
      showNotification("An error occurred while uploading the files", "error");
    } finally {
      setLoading(false);
      fetchRfqFiles();
    }
  };

  const handleFactoryChange = (event, newValue) => {
    setSelectedFactories(newValue);
  };

  const handleExpChange = (factoryId, value) => {
    setExpDays((prev) => ({ ...prev, [factoryId]: value }));
  };

  const handleRemoveFactory = (factoryId) => {
    setSelectedFactories((prev) =>
      prev.filter((factory) => factory._id !== factoryId)
    );
    setExpDays((prev) => {
      const newExpDays = { ...prev };
      delete newExpDays[factoryId];
      return newExpDays;
    });
  };

  const handleGetOffer = async () => {
    setLoading(true);
    const factoryIds = selectedFactories.map((factory) => factory._id);

    const alreadyAssignedFactory = rfqDetail.offers?.some(
      (offer) => offer.factory && factoryIds.includes(offer.factory.toString())
    );

    if (alreadyAssignedFactory) {
      showNotification(
        "This RFQ already has a factory assigned. Please remove it before adding a new one.",
        "warning"
      );
      return; // İzin vermeden fonksiyondan çık
    }

    try {
      const offerData = await assignFactoryToRfq(id, factoryIds, expDays);
      setEditingOffer({
        offerId: offerData.offerId,
        values: offerData.prices,
        expressValues: offerData.expressPrices || {},
        notes: offerData.notes,
      });
      showNotification("Offer request sent successfully!", "success");
      fetchRfqDetail();
    } catch (error) {
      showNotification("Error sending offer request!", "error");
      console.error("Error sending offer request:", error);
    } finally {
      setLoading(false);
    }
  };

  const acceptOfferFunc = async (rfqId, offerId, factoryId) => {
    try {
      const response = await acceptOffer(rfqId, offerId, factoryId); // API isteğini burada yapıyoruz
      if (response.success) {
        showNotification("Offer accepted successfully!", "success");
        fetchRfqDetail();
      } else {
        showNotification("Failed to accept the offer.", "error");
      }
    } catch (error) {
      console.error("Error accepting the offer:", error);
      showNotification("An error occurred while accepting the offer.", "error");
    }
  };
  const handleIconClick = async (offer) => {
    if (offer && offer._id) {
      console.log("Selected Offer: ", offer);
      try {
        const historyData = await getOfferHistory(offer._id);
        setEditingOffer({
          offerId: offer._id,
          values: offer.prices || {},
          expressValues: offer.expressPrices || {},
          history: historyData,
        });
        setDrawerOpen(true);
      } catch (error) {
        console.error("Error fetching offer history:", error);
        showNotification("Error fetching offer history", "error");
      }
    } else {
      console.error("Teklif bulunamadı veya geçersiz:", offer);
    }
  };

  const acceptForManagerFunc = async (rfqId, offerId) => {
    try {
      await acceptOfferForManager(rfqId, offerId);
    } catch (error) {
      showNotification("Error accepting the offer!", "error");
      console.error("Error accepting the offer:", error);
    }
    fetchRfqDetail();
  };

  const saveRfqNote = async () => {
    try {
      await updateRfqNote(id, rfqDetail.rfqNote);
      showNotification("RFQ note saved successfully!", "success");
    } catch (error) {
      showNotification("Error saving RFQ note!", "error");
      console.error("Error saving RFQ note:", error);
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setEditingOffer({
      offerId: null,
      values: {},
      expressValues: {},
      notes: "",
    });
  };

  const handleSaveChanges = async () => {
    try {
      await reviseOffer(userId, {
        offerId: editingOffer.offerId,
        prices: editingOffer.values,
        expressPrices: editingOffer.expressValues, // express fiyatlarını ekleyin
      });
      showNotification("Offer updated successfully!", "success");
      handleDrawerClose();
      fetchRfqDetail(); // RFQ detaylarını güncelle
    } catch (error) {
      showNotification("Error updating offer!", "error");
      console.error("Error updating offer:", error);
    }
  };

  const reviewToManager = async (factoryId) => {
    try {
      await sendToManager(id, rfqDetail.sales.salesPerson, factoryId);
      showNotification("RFQ sent to manager for review!", "success");
      navigate("/");
    } catch (error) {
      showNotification("Error sending RFQ to manager!", "error");
      console.error("Error sending RFQ to manager:", error);
    }
  };

  const availableFactories = factories.filter(
    (factory) =>
      !selectedFactories.some((selected) => selected._id === factory._id)
  );

  const assignedFactories =
    rfqDetail.offers?.map((offer) => {
      const factoryData = factories.find(
        (factory) => factory._id === offer.factory
      );
      return {
        id: offer.factory,
        name: factoryData ? factoryData.name : "Unknown Factory",
        status: offer.status || "waiting",
        prices: offer.prices || {},
        note: offer.note || "",
        expressPrices: offer.expressPrices || {}, // Burada doğru şekilde alındığını kontrol edin
      };
    }) || [];

  const lowestTotals = getLowestTotalByQuantity(assignedFactories); // En düşük toplamları hesapla

  const [deleteFileDialogOpen, setDeleteFileDialogOpen] = useState(false);
  const [selectedFileToDelete, setSelectedFileToDelete] = useState(null);

  const handleDeleteFile = async () => {
    if (!selectedFileToDelete) return;

    try {
      await deleteRfqFile(id, selectedFileToDelete._id);
      showNotification("File deleted successfully!", "success");
      fetchRfqFiles(); // Dosya listesini yenile
    } catch (error) {
      showNotification("Error deleting file!", "error");
      console.error("Error deleting file:", error);
    } finally {
      setDeleteFileDialogOpen(false);
      setSelectedFileToDelete(null);
    }
  };

  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [managerNote, setManagerNote] = useState("");
  const [selectedFactoryId, setSelectedFactoryId] = useState(null);

  const handleRequestRequotation = (factoryId) => {
    setSelectedFactoryId(factoryId);
    setOpenNoteModal(true);
  };

  const handleSubmitNote = () => {
    requestRequotation(id, selectedFactoryId, managerNote)
      .then(() => {
        showNotification("Request sent to purchase successfully!", "success");
        fetchRfqDetail();
        setOpenNoteModal(false);
        setManagerNote("");
      })
      .catch((error) => {
        showNotification("Error sending request to purchase!", "error");
        console.error("Error:", error);
      });
  };

  const loadSalesOfferHistory = async (offer) => {
    if (offer && offer._id) {
      try {
        const historyData = await getOfferHistory(offer._id);
        console.log("Sales Offer History Data:", historyData);

        if (historyData && historyData.length > 0) {
          // Get all sales entries
          const salesEntries = historyData.filter(
            (entry) => entry.userRole === "sales"
          );

          console.log("All Sales Entries:", salesEntries);

          if (salesEntries.length > 0) {
            // Sort by revision number in descending order to get the latest revision
            salesEntries.sort((a, b) => b.revision - a.revision);

            // Get the latest sales entry (highest revision number)
            const latestSalesEntry = salesEntries[0];
            console.log("Latest Sales Entry by Revision:", latestSalesEntry);

            // Update the state with the factory id as the key
            setSalesOfferHistory((prev) => ({
              ...prev,
              [offer.factory]: latestSalesEntry,
            }));
          }
        }
      } catch (error) {
        console.error("Error loading sales offer history:", error);
        showNotification("Failed to load sales offer history", "error");
      }
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Grid container spacing={3}>
        {/* Factory Assignment Section */}
        <Grid item xs={12} md={6}>
          {userRole !== "factoryUser" && (
            <TitleBox title="Factory Assignment" />
          )}
          <Grid container spacing={2}>
            {userRole !== "factoryUser" && (
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  ml: 2,
                  mt: 3,
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                  borderRadius: "5px",
                  p: 2,
                  pb: 4,
                }}
              >
                <Autocomplete
                  multiple
                  options={availableFactories}
                  getOptionLabel={(option) => option.name}
                  onChange={handleFactoryChange}
                  value={selectedFactories}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Factories"
                      placeholder="Select factories"
                      size="small"
                    />
                  )}
                  sx={{ mb: 3, mt: 2 }}
                />

                {selectedFactories.map((factory) => (
                  <Card key={factory._id} variant="outlined" sx={{ mb: 2 }}>
                    <CardContent
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: "16px!important",
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        {factory.name}
                      </Typography>
                      <Box display="flex" alignItems="center">
                        <TextField
                          label="Exp (days)"
                          type="number"
                          size="small"
                          value={expDays[factory._id] || ""}
                          onChange={(e) =>
                            handleExpChange(factory._id, e.target.value)
                          }
                          sx={{
                            maxWidth: 100,
                            mr: 2,
                          }}
                        />
                        <IconButton
                          onClick={() => handleRemoveFactory(factory._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleGetOffer}
                  disabled={loading || selectedFactories.length === 0}
                  size="small"
                  sx={{ minWidth: 150, color: "white!important" }}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Get an Offer"
                  )}
                </Button>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <TitleBox title="Files Upload" />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                ml: 2,
                mt: 1,
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                borderRadius: "5px",
                p: 2,
              }}
            >
              <Box mt={2}>
                <TextField
                  type="file"
                  inputProps={{ multiple: true }} // Birden fazla dosya seçimine izin verir
                  onChange={handleFileChange}
                  fullWidth
                />
                <List>
                  {selectedFiles &&
                    Array.from(selectedFiles).map((file, index) => (
                      <ListItem
                        key={index}
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleRemoveFile(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                      >
                        <ListItemText primary={file.name} />
                      </ListItem>
                    ))}
                </List>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFileUpload}
                  disabled={!selectedFiles || selectedFiles.length === 0} // Dosya seçilmemişse buton devre dışı
                  sx={{ color: "white!important" }}
                  size="small"
                >
                  Upload Files
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <TitleBox title="Rfq Files" />
            </Grid>
            <Grid
              sx={{
                ml: 2,
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                borderRadius: "5px",
                p: 2,
                mt: 1,
              }}
              item
              xs={12}
              md={12}
            >
              <List>
                {rfqFiles.length > 0 &&
                  rfqFiles.map((file, index) => (
                    <ListItem
                      key={index}
                      divider={index !== rfqFiles.length - 1}
                    >
                      <ListItemAvatar>
                        <Avatar>{getFileIcon(file.filename)}</Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={file.filename} />
                      <Box>
                        <IconButton
                          edge="end"
                          aria-label="download"
                          component="a"
                          href={`https://rfq-api.odakpcb.com/${file.filepath}`}
                          download
                          target="_blank"
                        >
                          <GetAppIcon />
                        </IconButton>
                        {userRole !== "factoryUser" && ( // Factory user'lar silme butonunu görmesin
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              setSelectedFileToDelete(file);
                              setDeleteFileDialogOpen(true);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Box>
                    </ListItem>
                  ))}
              </List>
            </Grid>

            {userRole !== "factoryUser" && (
              <>
                <Grid item xs={12} md={12}>
                  <TitleBox title="Rfq Notes" />
                </Grid>
                <Grid
                  sx={{
                    ml: 2,
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                    borderRadius: "5px",
                    p: 2,
                    mt: 1,
                  }}
                  item
                  xs={12}
                  md={12}
                >
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    label="RFQ Notes"
                    value={rfqDetail.rfqNote || ""}
                    onChange={(e) => {
                      setRfqDetail((prev) => ({
                        ...prev,
                        rfqNote: e.target.value,
                      }));
                    }}
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={saveRfqNote}
                    disabled={loading}
                    size="small"
                    sx={{ mt: 2, color: "white!important" }}
                  >
                    Save Note
                  </Button>
                </Grid>
              </>
            )}
            {userRole === "factoryUser" && (
              <>
                <Grid item xs={12} md={12}>
                  <TitleBox title="Factory Files" />
                </Grid>
                <Grid
                  sx={{
                    ml: 2,
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                    borderRadius: "5px",
                    p: 2,
                    mt: 1,
                  }}
                  item
                  xs={12}
                  md={12}
                >
                  <List>
                    {offerFiles.length > 0 ? (
                      offerFiles
                        .filter((file) => file.uploadedBy === userId)
                        .map((file, index) => (
                          <ListItem
                            key={index}
                            divider={index !== offerFiles.length - 1}
                          >
                            <ListItemAvatar>
                              <Avatar>{getFileIcon(file.filename)}</Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={file.filename} />
                            <IconButton
                              edge="end"
                              aria-label="download"
                              component="a"
                              href={`https://rfq-api.odakpcb.com/${file.filepath}`}
                              download
                              target="_blank"
                            >
                              <GetAppIcon />
                            </IconButton>
                          </ListItem>
                        ))
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        No files uploaded.
                      </Typography>
                    )}
                  </List>
                </Grid>
              </>
            )}
            {userRole === "factoryUser" && (
              <FactoryUserNote
                rfq={rfqDetail}
                rfqId={id}
                factoryUserId={userId}
                initialNotes={
                  rfqDetail.offers?.find(
                    (offer) => offer.factoryUser === userId
                  )?.note || ""
                } // Notlar
              />
            )}
          </Grid>
        </Grid>
        {/* RFQ Details Section */}
        <Grid item xs={12} md={6}>
          <Grid item xs={12} md={12}>
            <TitleBox title="Rfq Details" />
          </Grid>
          <Grid item xs={12} md={12}>
            <RfqInfoCard rfqDetail={rfqDetail} />
          </Grid>
          {userRole === "factoryUser" && (
            <>
              <Grid item sx={{ mt: 2 }} xs={12} md={12}>
                <TitleBox title="Rfq Note" />
              </Grid>
              <Grid
                sx={{
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                  borderRadius: "5px",
                  p: 2,
                  mt: 1,
                }}
                item
                xs={12}
                md={12}
              >
                <Typography>{rfqDetail.rfqNote}</Typography>
              </Grid>
            </>
          )}
        </Grid>

        {/* FactoryUser Specific Input Section */}
        {/* FactoryUser Specific Input Section */}
        {userRole === "factoryUser" && rfqDetail.offers && (
          <Grid item xs={12}>
            <FactoryUserInput
              rfq={rfqDetail}
              rfqId={id}
              factoryUserId={userId}
              initialPrices={rfqDetail.offers[0]?.prices || {}}
              initialExpressPrices={rfqDetail.offers[0]?.expressPrices || {}}
              onValueChange={handleValueChange}
              fetchRfqDetail={fetchRfqDetail}
            />
          </Grid>
        )}
        {/* Assigned Factories & Offers Section */}
        {userRole !== "factoryUser" &&
          userRole !== "cr" &&
          userRole !== "technical" &&
          rfqDetail.managerAcceptance.status !== true && (
            <Grid item xs={12}>
              <TitleBox
                title="Assigned Factories & Offers"
                status={rfqDetail.generalInfo.status}
              />
              <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fit, minmax(350px, 1fr))"
                gap={2}
                sx={{
                  mt: 1,
                }}
              >
                {assignedFactories.map((factory, index) => (
                  <Card
                    key={index}
                    variant="outlined"
                    sx={{
                      minWidth: 350,
                      p: 2,
                      boxShadow: "none",
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <CardContent>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {/* Sales person last offer */}
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold" }}
                          >
                            {factory.name}
                          </Typography>

                          {offers.find(
                            (offer) => offer.factory === factory.id
                          ) && (
                            <Tooltip
                              title={
                                <React.Fragment>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ fontWeight: "bold", mb: 1 }}
                                  >
                                    Sales Person's Last Offer
                                  </Typography>

                                  {/* Standard Prices */}
                                  <Typography
                                    variant="body2"
                                    sx={{ fontWeight: "bold", mt: 1 }}
                                  >
                                    Standard Prices
                                  </Typography>
                                  <TableContainer
                                    component={Paper}
                                    sx={{ maxWidth: 600, mb: 2 }}
                                  >
                                    <Table size="medium">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Pieces</TableCell>
                                          <TableCell>Lead Time</TableCell>
                                          <TableCell>Unit</TableCell>
                                          <TableCell>Tooling</TableCell>
                                          <TableCell>E-test</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {salesOfferHistory[factory.id] &&
                                          Object.entries(
                                            salesOfferHistory[factory.id]
                                              .prices || {}
                                          ).map(([quantity, priceDetails]) => (
                                            <TableRow
                                              key={`history-${quantity}`}
                                            >
                                              <TableCell>{quantity}</TableCell>
                                              <TableCell>
                                                {priceDetails.lt}
                                              </TableCell>
                                              <TableCell>
                                                {priceDetails.unit}
                                              </TableCell>
                                              <TableCell>
                                                {priceDetails.tooling}
                                              </TableCell>
                                              <TableCell>
                                                {priceDetails.etest}
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        {salesOfferHistory[factory.id] &&
                                          Object.entries(
                                            salesOfferHistory[factory.id]
                                              .prices || {}
                                          ).length === 0 && (
                                            <TableRow>
                                              <TableCell
                                                colSpan={5}
                                                align="center"
                                              >
                                                No standard prices available
                                              </TableCell>
                                            </TableRow>
                                          )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>

                                  {/* Express Prices */}
                                  <Typography
                                    variant="body2"
                                    sx={{ fontWeight: "bold", mt: 1 }}
                                  >
                                    Express Prices
                                  </Typography>
                                  <TableContainer
                                    component={Paper}
                                    sx={{ maxWidth: 600 }}
                                  >
                                    <Table size="medium">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Pieces</TableCell>
                                          <TableCell>Exp. Lead Time</TableCell>
                                          <TableCell>Exp. Unit</TableCell>
                                          <TableCell>Exp. Tooling</TableCell>
                                          <TableCell>Exp. E-test</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {salesOfferHistory[factory.id] &&
                                          Object.entries(
                                            salesOfferHistory[factory.id]
                                              .expressPrices || {}
                                          ).map(([quantity, priceDetails]) => (
                                            <TableRow
                                              key={`exp-history-${quantity}`}
                                            >
                                              <TableCell>{quantity}</TableCell>
                                              <TableCell>
                                                {priceDetails.expLt}
                                              </TableCell>
                                              <TableCell>
                                                {priceDetails.expUnit}
                                              </TableCell>
                                              <TableCell>
                                                {priceDetails.expTooling}
                                              </TableCell>
                                              <TableCell>
                                                {priceDetails.expEtest}
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        {salesOfferHistory[factory.id] &&
                                          Object.entries(
                                            salesOfferHistory[factory.id]
                                              .expressPrices || {}
                                          ).length === 0 && (
                                            <TableRow>
                                              <TableCell
                                                colSpan={5}
                                                align="center"
                                              >
                                                No express prices available
                                              </TableCell>
                                            </TableRow>
                                          )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </React.Fragment>
                              }
                              arrow
                              placement="bottom-start"
                              PopperProps={{
                                sx: {
                                  "& .MuiTooltip-tooltip": {
                                    maxWidth: 650,
                                    padding: 2,
                                  },
                                },
                              }}
                            >
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                sx={{ ml: 1 }}
                              >
                                <InfoIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                        <Chip
                          variant="outlined"
                          size="small"
                          label={
                            factory.status === "submitted"
                              ? "Offer Received"
                              : factory.status === "pending"
                              ? "Waiting"
                              : factory.status === "revised"
                              ? "Revised"
                              : factory.status === "accepted"
                              ? "Accepted"
                              : factory.status === "rejected"
                              ? "Rejected"
                              : factory.status === "manager_review"
                              ? "Waiting for Manager"
                              : "Pending"
                          }
                          color={
                            factory.status === "submitted"
                              ? "primary"
                              : factory.status === "pending" ||
                                factory.status === "revised"
                              ? "warning"
                              : factory.status === "accepted"
                              ? "success"
                              : "error"
                          }
                          sx={{ ml: 2 }}
                        />
                      </Box>
                      <Divider sx={{ my: 1 }} />

                      {/* Normal ve Express Fiyatları Gösterme */}
                      <Box>
                        <TableContainer
                          component={Paper}
                          elevation={0}
                          sx={{ boxShadow: "none" }}
                        >
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Pieces</TableCell>
                                <TableCell>M²</TableCell>
                                <TableCell>Lead Time</TableCell>
                                <TableCell>Unit Price</TableCell>
                                <TableCell>Tooling</TableCell>
                                <TableCell>E-test</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>M² Price</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {/* Normal Fiyatları Gösterme */}
                              {Object.entries(factory.prices || {}).map(
                                ([quantity, priceDetails]) => {
                                  const totalPrice =
                                    priceDetails.unit * quantity +
                                    priceDetails.tooling +
                                    priceDetails.etest;

                                  const isLowestTotal =
                                    lowestTotals[quantity]?.factoryId ===
                                      factory.id &&
                                    lowestTotals[quantity]?.type === "normal"; // En düşük normal fiyat mı?

                                  return (
                                    <TableRow
                                      key={`normal-${quantity}`}
                                      sx={{
                                        backgroundColor: isLowestTotal
                                          ? "#b9f6ca"
                                          : "transparent", // En düşük fiyatı yeşil yap
                                      }}
                                    >
                                      <TableCell>{quantity}</TableCell>
                                      <TableCell>
                                        {calculatePanelArea(
                                          rfqDetail.dimensions,
                                          quantity
                                        )}
                                      </TableCell>
                                      <TableCell>{priceDetails.lt}</TableCell>
                                      <TableCell>{priceDetails.unit}</TableCell>
                                      <TableCell>
                                        {priceDetails.tooling}
                                      </TableCell>
                                      <TableCell>
                                        {priceDetails.etest}
                                      </TableCell>
                                      <TableCell>{totalPrice}</TableCell>
                                      <TableCell>
                                        {(
                                          totalPrice /
                                          calculatePanelArea(
                                            rfqDetail.dimensions,
                                            quantity
                                          )
                                        ).toFixed(4)}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}

                              {/* Express Fiyatları Gösterme */}
                              {Object.entries(factory.expressPrices || {}).map(
                                ([quantity, expPriceDetails]) => {
                                  const totalExpressPrice =
                                    expPriceDetails.expUnit * quantity +
                                    expPriceDetails.expTooling +
                                    expPriceDetails.expEtest;

                                  const isLowestExpressTotal =
                                    lowestTotals[quantity]?.factoryId ===
                                      factory.id &&
                                    lowestTotals[quantity]?.type === "normal"; // En düşük express fiyat mı?

                                  return (
                                    <TableRow
                                      key={`express-${quantity}`}
                                      sx={{
                                        backgroundColor: isLowestExpressTotal
                                          ? "#b9f6ca"
                                          : "transparent", // En düşük fiyatı yeşil yap
                                      }}
                                    >
                                      <TableCell
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: 5,
                                        }}
                                      >
                                        {quantity}{" "}
                                        <Chip label="Exp" size="small" />
                                      </TableCell>
                                      <TableCell>
                                        {calculatePanelArea(
                                          rfqDetail.dimensions,
                                          quantity
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {expPriceDetails.expLt}
                                      </TableCell>
                                      <TableCell>
                                        {expPriceDetails.expUnit}
                                      </TableCell>
                                      <TableCell>
                                        {expPriceDetails.expTooling}
                                      </TableCell>
                                      <TableCell>
                                        {expPriceDetails.expEtest}
                                      </TableCell>
                                      <TableCell>{totalExpressPrice}</TableCell>
                                      <TableCell>
                                        {(
                                          totalExpressPrice /
                                          calculatePanelArea(
                                            rfqDetail.dimensions,
                                            quantity
                                          )
                                        ).toFixed(4)}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        {/* Dosyaları gösterme bölümü */}
                        <Box mt={2}>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: "bold" }}
                          >
                            Files:
                          </Typography>
                          {offerFiles.filter(
                            (file) => file.factoryId === factory.id
                          ).length > 0 ? (
                            <List>
                              {offerFiles
                                .filter((file) => file.factoryId === factory.id)
                                .map((file, fileIndex) => (
                                  <ListItem
                                    key={fileIndex}
                                    divider={
                                      fileIndex !==
                                      offerFiles.filter(
                                        (f) => f.factoryId === factory.id
                                      ).length -
                                        1
                                    }
                                  >
                                    <ListItemAvatar>
                                      <Avatar>
                                        {getFileIcon(file.filename)}
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={file.filename} />
                                    <IconButton
                                      edge="end"
                                      aria-label="download"
                                      component="a"
                                      href={`https://rfq-api.odakpcb.com/${file.filepath}`}
                                      download
                                      target="_blank"
                                    >
                                      <GetAppIcon />
                                    </IconButton>
                                  </ListItem>
                                ))}
                            </List>
                          ) : (
                            <Typography variant="body2" color="textSecondary">
                              No files uploaded.
                            </Typography>
                          )}
                        </Box>

                        {/* Notları gösterme bölümü */}
                        <Box mt={2}>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: "bold" }}
                          >
                            Notes:
                          </Typography>
                          {factory.note ? (
                            <Typography variant="body2">
                              {factory.note}
                            </Typography>
                          ) : (
                            <Typography variant="body2" color="textSecondary">
                              No notes provided.
                            </Typography>
                          )}
                        </Box>
                        {offers.find((offer) => offer.factory === factory.id)
                          ?.managerNote && (
                          <Box mt={2}>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: "bold" }}
                            >
                              Manager Note:
                            </Typography>

                            <Typography variant="body2">
                              {
                                offers.find(
                                  (offer) => offer.factory === factory.id
                                ).managerNote
                              }
                            </Typography>
                          </Box>
                        )}
                      </Box>

                      {/* Butonlar Bölümü */}
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        gap={1}
                        mt={2}
                      >
                        {factory.status !== "accepted" &&
                          factory.status !== "revised" &&
                          factory.status !== "pending" &&
                          factory.status !== "assigned_to_factory" &&
                          offers.find((offer) => offer.factory === factory.id)
                            .status !== "manager_review" &&
                          userRole === "sales" && (
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              sx={{ color: "white!important" }}
                              onClick={() => {
                                const offer = offers.find(
                                  (offer) => offer.factory === factory.id
                                );
                                handleIconClick(offer);
                              }}
                            >
                              Re Quotation
                            </Button>
                          )}
                        {factory.status !== "accepted" &&
                          factory.status !== "revised" &&
                          factory.status !== "pending" &&
                          factory.status !== "assigned_to_factory" &&
                          offers.find((offer) => offer.factory === factory.id)
                            .status === "accepted" &&
                          offers.find((offer) => offer.factory === factory.id)
                            .status !== "manager_review" &&
                          userRole === "sales" && (
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              sx={{ color: "white!important" }}
                              onClick={() => {
                                const offer = offers.find(
                                  (offer) => offer.factory === factory.id
                                );
                                handleIconClick(offer);
                              }}
                            >
                              Rollback
                            </Button>
                          )}
                        {factory.status !== "accepted" &&
                          factory.status !== "revised" &&
                          factory.status !== "pending" &&
                          factory.status !== "assigned_to_factory" &&
                          offers.find((offer) => offer.factory === factory.id)
                            .status !== "manager_review" &&
                          userRole === "sales" && (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  const offer = offers.find(
                                    (offer) => offer.factory === factory.id
                                  );
                                  acceptOfferFunc(
                                    rfqDetail._id,
                                    offer._id,
                                    factory._id
                                  );
                                }}
                                size="small"
                                sx={{ color: "white!important" }}
                              >
                                Accept
                              </Button>

                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                sx={{ color: "white!important" }}
                                onClick={async () => {
                                  await acceptAllOffers(rfqDetail._id);
                                  fetchRfqDetail(); // Refresh the state after accepting all offers
                                }}
                              >
                                Accept All
                              </Button>
                            </>
                          )}
                        {factory.status === "accepted" &&
                          userRole == "sales" && (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                sx={{ color: "white!important" }}
                                onClick={() => reviewToManager(factory.id)}
                              >
                                Send to Manager
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                sx={{ color: "white!important" }}
                                onClick={async () => {
                                  await unAcceptOffer(
                                    rfqDetail._id,
                                    offers.find(
                                      (offer) => offer.factory === factory.id
                                    )._id,
                                    factory._id
                                  );
                                  fetchRfqDetail(); // Refresh the state after unaccepting
                                }}
                              >
                                Unaccept
                              </Button>
                            </>
                          )}
                        {userRole === "manager" &&
                          offers.find((offer) => offer.factory === factory.id)
                            .status === "manager_review" && (
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              sx={{ color: "white!important" }}
                              onClick={() =>
                                handleRequestRequotation(factory.id)
                              }
                            >
                              Send to Purchase
                            </Button>
                          )}
                        ,
                        {userRole === "manager" &&
                          offers.find((offer) => offer.factory === factory.id)
                            .status === "manager_review" && (
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              sx={{ color: "white!important" }}
                              onClick={() =>
                                handleDialogOpen(
                                  offers.find(
                                    (offer) => offer.factory === factory.id
                                  ),
                                  factory.name // Fabrika ismini buradan alıyoruz
                                )
                              }
                            >
                              Choose
                            </Button>
                          )}
                      </Box>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </Grid>
          )}

        {/* Manager Acceptance Section */}
        {userRole === "manager" &&
          rfqDetail.managerAcceptance.status === true &&
          rfqDetail.generalInfo.status !== "closed" && (
            <Grid item xs={12}>
              <TitleBox title="Selling Prices" />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" }, // Mobilde tek satır, geniş ekranda yan yana
                  justifyContent: "space-between",
                  gap: 3, // İki tablo arasındaki boşluk
                  mt: 3,
                }}
              >
                {/* Normal Prices */}
                <Box
                  sx={{
                    flex: "1",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                    borderRadius: "5px",
                    p: 2,
                    pb: 4,
                    width: { xs: "100%", md: "48%" }, // Mobilde tam genişlik, masaüstünde 48%
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", mt: 2, ml: 2 }}
                  >
                    {rfqDetail.acceptedOffer?.factory?.name} Prices
                  </Typography>

                  <TableContainer elevation={0} sx={{ boxShadow: "none" }}>
                    <Table size="medium">
                      <TableHead>
                        <TableRow>
                          <TableCell>Pieces</TableCell>
                          <TableCell>M²</TableCell>
                          <TableCell>Lead Time</TableCell>
                          <TableCell>Unit Price</TableCell>
                          <TableCell>Tooling</TableCell>
                          <TableCell>E-test</TableCell>
                          <TableCell>Total</TableCell>
                          <TableCell>M² Price</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* Normal Prices */}
                        {Object.entries(
                          rfqDetail.acceptedOffer.prices || {}
                        ).map(([quantity, priceDetails]) => {
                          const area = calculatePanelArea(
                            rfqDetail.dimensions,
                            quantity
                          ); // M² alanını hesapla
                          const totalPrice =
                            priceDetails.unit * quantity +
                            priceDetails.tooling +
                            priceDetails.etest;
                          const m2Price = totalPrice / area; // M² Price

                          return (
                            <TableRow className="price-table" key={quantity}>
                              <TableCell>{quantity}</TableCell>
                              <TableCell>{area}</TableCell>
                              <TableCell>{priceDetails.lt}</TableCell>
                              <TableCell>{priceDetails.unit}</TableCell>
                              <TableCell>{priceDetails.tooling}</TableCell>
                              <TableCell>{priceDetails.etest}</TableCell>
                              <TableCell>{totalPrice.toFixed(4)}</TableCell>
                              <TableCell>{m2Price.toFixed(4)}</TableCell>
                            </TableRow>
                          );
                        })}

                        {/* Express Prices (if available) */}
                        {Object.entries(
                          rfqDetail.acceptedOffer.expressPrices || {}
                        ).length > 0 && (
                          <>
                            {Object.entries(
                              rfqDetail.acceptedOffer.expressPrices || {}
                            ).map(([quantity, expPriceDetails]) => {
                              const area = calculatePanelArea(
                                rfqDetail.dimensions,
                                quantity
                              ); // M² alanını hesapla
                              const totalExpressPrice =
                                expPriceDetails.expUnit * quantity +
                                expPriceDetails.expTooling +
                                expPriceDetails.expEtest;
                              const m2ExpressPrice = totalExpressPrice / area; // M² Express Price

                              return (
                                <TableRow
                                  className="price-table"
                                  key={quantity}
                                >
                                  <TableCell>
                                    <Chip
                                      label={`EXP ${quantity}`}
                                      size="medium"
                                    />
                                  </TableCell>
                                  <TableCell>{area}</TableCell>
                                  <TableCell>{expPriceDetails.expLt}</TableCell>
                                  <TableCell>
                                    {expPriceDetails.expUnit}
                                  </TableCell>
                                  <TableCell>
                                    {expPriceDetails.expTooling}
                                  </TableCell>
                                  <TableCell>
                                    {expPriceDetails.expEtest}
                                  </TableCell>
                                  <TableCell>
                                    {totalExpressPrice.toFixed(4)}
                                  </TableCell>
                                  <TableCell>
                                    {m2ExpressPrice.toFixed(4)}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                {/* Hesaplama Alanı */}
                {/* Hesaplama Alanı */}
                <Box
                  sx={{
                    flex: "1",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                    borderRadius: "5px",
                    p: 2,
                    pb: 4,
                    width: { xs: "100%", md: "48%" },
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 10 }}>
                    Set Selling Prices
                  </Typography>

                  {/* Normal Prices Hesaplama Inputları */}
                  {Object.entries(rfqDetail.acceptedOffer.prices || {}).map(
                    ([quantity]) => (
                      <Box key={quantity} sx={{ mb: 3 }}>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", mb: 1 }}
                        >
                          Pieces: {quantity}
                        </Typography>

                        <Box sx={{ display: "flex", gap: 2 }}>
                          {/* Unit Price Input */}
                          <TextField
                            label="Unit Price"
                            type="number"
                            value={unitPrice[quantity] || ""}
                            onChange={(e) =>
                              handleInputChange(e, quantity, "unitPrice")
                            }
                            size="small"
                            sx={{ flex: 1 }} // Alanı eşit şekilde böl
                          />

                          {/* Tooling Input */}
                          <TextField
                            label="Tooling"
                            type="number"
                            value={tooling[quantity] ?? ""} // Nullish coalescing operator kullanımı
                            onChange={(e) =>
                              handleInputChange(e, quantity, "tooling")
                            }
                            size="small"
                            sx={{ flex: 1 }}
                          />

                          {/* E-test Input */}
                          <TextField
                            label="E-test"
                            type="number"
                            value={etest[quantity] ?? ""} // Nullish coalescing operator kullanımı
                            onChange={(e) =>
                              handleInputChange(e, quantity, "etest")
                            }
                            size="small"
                            sx={{ flex: 1 }}
                          />

                          {/* Total Input */}
                          <TextField
                            label="Total Price"
                            type="number"
                            value={totalPrice[quantity] || ""}
                            onChange={(e) =>
                              handleInputChange(e, quantity, "totalPrice")
                            }
                            size="small"
                            sx={{ flex: 1 }} // Alanı eşit şekilde böl
                          />

                          {/* M² Price Input */}
                          <TextField
                            label="M² Price"
                            type="number"
                            value={m2Price[quantity] || ""}
                            onChange={(e) =>
                              handleInputChange(e, quantity, "m2Price")
                            }
                            size="small"
                            sx={{ flex: 1 }} // Alanı eşit şekilde böl
                          />
                        </Box>
                      </Box>
                    )
                  )}

                  {/* Express Prices Hesaplama Inputları */}
                  {Object.entries(rfqDetail.acceptedOffer.expressPrices || {})
                    .length > 0 && (
                    <>
                      {Object.entries(
                        rfqDetail.acceptedOffer.expressPrices || {}
                      ).map(([quantity]) => (
                        <Box key={quantity} sx={{ mb: 3 }}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", mb: 1 }}
                          >
                            Express Pieces: {quantity}
                          </Typography>

                          <Box sx={{ display: "flex", gap: 2 }}>
                            {/* Express Unit Price Input */}
                            <TextField
                              label="Express Unit Price"
                              type="number"
                              value={expUnitPrice[quantity] || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  quantity,
                                  "expUnitPrice",
                                  true
                                )
                              }
                              size="small"
                              sx={{ flex: 1 }} // Alanı eşit şekilde böl
                            />
                            {/* Express Tooling Input */}
                            <TextField
                              label="Express Tooling"
                              type="number"
                              value={expTooling[quantity] ?? ""} // Nullish coalescing kullanımı
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  quantity,
                                  "expTooling",
                                  true
                                )
                              }
                              size="small"
                              sx={{ flex: 1 }} // Alanı eşit şekilde böl
                            />

                            {/* Express E-test Input */}
                            <TextField
                              label="Express E-test"
                              type="number"
                              value={expEtest[quantity] ?? ""} // Nullish coalescing kullanımı
                              onChange={(e) =>
                                handleInputChange(e, quantity, "expEtest", true)
                              }
                              size="small"
                              sx={{ flex: 1 }} // Alanı eşit şekilde böl
                            />

                            {/* Express Total Input */}
                            <TextField
                              label="Express Total Price"
                              type="number"
                              value={expTotalPrice[quantity] || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  quantity,
                                  "expTotalPrice",
                                  true
                                )
                              }
                              size="small"
                              sx={{ flex: 1 }} // Alanı eşit şekilde böl
                            />

                            {/* Express M² Price Input */}
                            <TextField
                              label="Express M² Price"
                              type="number"
                              value={expM2Price[quantity] || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  quantity,
                                  "expM2Price",
                                  true
                                )
                              }
                              size="small"
                              sx={{ flex: 1 }} // Alanı eşit şekilde böl
                            />
                          </Box>
                        </Box>
                      ))}
                    </>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitSalesPrices}
                    size="small"
                    sx={{ color: "white!important" }}
                  >
                    Save Prices
                  </Button>
                </Box>
              </Box>
            </Grid>
          )}

        {userRole === "manager" &&
          rfqDetail.managerAcceptance.status === true &&
          rfqDetail.generalInfo.status === "closed" && (
            <Grid item xs={12}>
              <TitleBox title="Selling Prices" />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" }, // Mobilde tek satır, geniş ekranda yan yana
                  justifyContent: "space-between",
                  gap: 3, // İki tablo arasındaki boşluk
                  mt: 3,
                }}
              >
                {/* Normal Prices */}
                <Box
                  sx={{
                    flex: "1",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                    borderRadius: "5px",
                    p: 2,
                    pb: 4,
                    width: { xs: "100%", md: "48%" }, // Mobilde tam genişlik, masaüstünde 48%
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", mt: 2, ml: 2 }}
                  >
                    {rfqDetail.acceptedOffer?.factory?.name} Prices
                  </Typography>

                  <TableContainer elevation={0} sx={{ boxShadow: "none" }}>
                    <Table size="medium">
                      <TableHead>
                        <TableRow>
                          <TableCell>Pieces</TableCell>
                          <TableCell>M²</TableCell>
                          <TableCell>Lead Time</TableCell>
                          <TableCell>Unit Price</TableCell>
                          <TableCell>Tooling</TableCell>
                          <TableCell>E-test</TableCell>
                          <TableCell>Total</TableCell>
                          <TableCell>M² Price</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* Normal Prices */}
                        {Object.entries(
                          rfqDetail.acceptedOffer.prices || {}
                        ).map(([quantity, priceDetails]) => {
                          const area = calculatePanelArea(
                            rfqDetail.dimensions,
                            quantity
                          ); // M² alanını hesapla
                          const totalPrice =
                            priceDetails.unit * quantity +
                            priceDetails.tooling +
                            priceDetails.etest;
                          const m2Price = totalPrice / area; // M² Price

                          return (
                            <TableRow className="price-table" key={quantity}>
                              <TableCell>{quantity}</TableCell>
                              <TableCell>{area}</TableCell>
                              <TableCell>{priceDetails.lt}</TableCell>
                              <TableCell>{priceDetails.unit}</TableCell>
                              <TableCell>{priceDetails.tooling}</TableCell>
                              <TableCell>{priceDetails.etest}</TableCell>
                              <TableCell>{totalPrice.toFixed(4)}</TableCell>
                              <TableCell>{m2Price.toFixed(4)}</TableCell>
                            </TableRow>
                          );
                        })}

                        {/* Express Prices (if available) */}
                        {Object.entries(
                          rfqDetail.acceptedOffer.expressPrices || {}
                        ).length > 0 && (
                          <>
                            {Object.entries(
                              rfqDetail.acceptedOffer.expressPrices || {}
                            ).map(([quantity, expPriceDetails]) => {
                              const area = calculatePanelArea(
                                rfqDetail.dimensions,
                                quantity
                              ); // M² alanını hesapla
                              const totalExpressPrice =
                                expPriceDetails.expUnit * quantity +
                                expPriceDetails.expTooling +
                                expPriceDetails.expEtest;
                              const m2ExpressPrice = totalExpressPrice / area; // M² Express Price

                              return (
                                <TableRow
                                  className="price-table"
                                  key={quantity}
                                >
                                  <TableCell>
                                    <Chip
                                      label={`EXP ${quantity}`}
                                      size="medium"
                                    />
                                  </TableCell>
                                  <TableCell>{area}</TableCell>
                                  <TableCell>{expPriceDetails.expLt}</TableCell>
                                  <TableCell>
                                    {expPriceDetails.expUnit}
                                  </TableCell>
                                  <TableCell>
                                    {expPriceDetails.expTooling}
                                  </TableCell>
                                  <TableCell>
                                    {expPriceDetails.expEtest}
                                  </TableCell>
                                  <TableCell>
                                    {totalExpressPrice.toFixed(4)}
                                  </TableCell>
                                  <TableCell>
                                    {m2ExpressPrice.toFixed(4)}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box
                  sx={{
                    mt: 18,
                  }}
                >
                  {/* Normal Prices */}
                  {/* Normal Prices */}
                  {Object.entries(salesPricesState.unitPrice).map(
                    ([quantity]) => (
                      <Box key={quantity} sx={{ mb: 3 }}>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", mb: 1 }}
                        >
                          Pieces: {quantity}
                        </Typography>

                        <Box sx={{ display: "flex", gap: 2 }}>
                          <TextField
                            label="Unit Price"
                            type="number"
                            value={salesPricesState.unitPrice[quantity] ?? ""}
                            onChange={(e) =>
                              handleInputChangeClosed(e, quantity, "unitPrice")
                            }
                            size="small"
                            sx={{ flex: 1 }}
                          />
                          <TextField
                            label="Tooling"
                            type="number"
                            value={salesPricesState.tooling[quantity] ?? ""}
                            onChange={(e) =>
                              handleInputChangeClosed(e, quantity, "tooling")
                            }
                            size="small"
                            sx={{ flex: 1 }}
                          />
                          <TextField
                            label="E-test"
                            type="number"
                            value={salesPricesState.etest[quantity] ?? ""}
                            onChange={(e) =>
                              handleInputChangeClosed(e, quantity, "etest")
                            }
                            size="small"
                            sx={{ flex: 1 }}
                          />
                          <TextField
                            label="Total Price"
                            type="number"
                            value={salesPricesState.totalPrice[quantity] ?? ""}
                            onChange={(e) =>
                              handleInputChangeClosed(e, quantity, "totalPrice")
                            }
                            size="small"
                            sx={{ flex: 1 }}
                          />
                          <TextField
                            label="M² Price"
                            type="number"
                            value={salesPricesState.m2Price[quantity] ?? ""}
                            onChange={(e) =>
                              handleInputChangeClosed(e, quantity, "m2Price")
                            }
                            size="small"
                            sx={{ flex: 1 }}
                          />
                        </Box>
                      </Box>
                    )
                  )}

                  {/* Express Prices */}
                  {/* Express Prices */}
                  {Object.entries(salesPricesState.expUnitPrice).map(
                    ([quantity]) => (
                      <Box key={quantity} sx={{ mb: 3 }}>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", mb: 1 }}
                        >
                          Express Pieces: {quantity}
                        </Typography>

                        <Box sx={{ display: "flex", gap: 2 }}>
                          <TextField
                            label="Express Unit Price"
                            type="number"
                            value={
                              salesPricesState.expUnitPrice[quantity] ?? ""
                            }
                            onChange={(e) =>
                              handleInputChangeClosed(
                                e,
                                quantity,
                                "expUnitPrice",
                                true
                              )
                            }
                            size="small"
                            sx={{ flex: 1 }}
                          />
                          <TextField
                            label="Express Tooling"
                            type="number"
                            value={salesPricesState.expTooling[quantity] ?? ""}
                            onChange={(e) =>
                              handleInputChangeClosed(
                                e,
                                quantity,
                                "expTooling",
                                true
                              )
                            }
                            size="small"
                            sx={{ flex: 1 }}
                          />
                          <TextField
                            label="Express E-test"
                            type="number"
                            value={salesPricesState.expEtest[quantity] ?? ""}
                            onChange={(e) =>
                              handleInputChangeClosed(
                                e,
                                quantity,
                                "expEtest",
                                true
                              )
                            }
                            size="small"
                            sx={{ flex: 1 }}
                          />
                          <TextField
                            label="Express Total Price"
                            type="number"
                            value={
                              salesPricesState.expTotalPrice[quantity] ?? ""
                            }
                            onChange={(e) =>
                              handleInputChangeClosed(
                                e,
                                quantity,
                                "expTotalPrice",
                                true
                              )
                            }
                            size="small"
                            sx={{ flex: 1 }}
                          />
                          <TextField
                            label="Express M² Price"
                            type="number"
                            value={salesPricesState.expM2Price[quantity] ?? ""}
                            onChange={(e) =>
                              handleInputChangeClosed(
                                e,
                                quantity,
                                "expM2Price",
                                true
                              )
                            }
                            size="small"
                            sx={{ flex: 1 }}
                          />
                        </Box>
                      </Box>
                    )
                  )}

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitSalesPricesClosed}
                    size="small"
                    sx={{ color: "white!important" }}
                  >
                    Save Prices
                  </Button>
                </Box>
              </Box>
            </Grid>
          )}

        {userRole === "cr" && rfqDetail.generalInfo.status === "closed" && (
          <Grid item xs={12}>
            <TitleBox title="Selling Prices" />

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" }, // Mobilde tek satır, geniş ekranda yan yana
                justifyContent: "space-between",
                gap: 3, // İki tablo arasındaki boşluk
                mt: 3,
              }}
            >
              {/* Normal Prices */}
              <Box
                sx={{
                  flex: "1",
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                  borderRadius: "5px",
                  p: 2,
                  pb: 4,
                  width: { xs: "100%", md: "48%" }, // Mobilde tam genişlik, masaüstünde 48%
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mt: 2, ml: 2 }}
                >
                  Normal Prices
                </Typography>

                <TableContainer elevation={0} sx={{ boxShadow: "none" }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Pieces</TableCell>
                        <TableCell>M²</TableCell>
                        <TableCell>Lead Time</TableCell>
                        <TableCell>Unit Price</TableCell>
                        <TableCell>Tooling</TableCell>
                        <TableCell>E-test</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell>M² Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(
                        rfqDetail.sales?.salesPrices?.[0]?.prices || {}
                      ).map(([quantity, priceDetails]) => {
                        const area = calculatePanelArea(
                          rfqDetail.dimensions,
                          quantity
                        );
                        const totalPrice =
                          priceDetails.unit * quantity +
                          priceDetails.tooling +
                          priceDetails.etest;
                        const m2Price = totalPrice / area;

                        // Burada lt değerini acceptedOffer'dan alıyoruz.
                        const ltValue =
                          rfqDetail.acceptedOffer?.prices?.[quantity]?.lt ||
                          priceDetails.lt;

                        return (
                          <TableRow className="price-table" key={quantity}>
                            <TableCell>{quantity}</TableCell>
                            <TableCell>{area}</TableCell>
                            <TableCell>{ltValue}</TableCell>
                            <TableCell>{priceDetails.unit}</TableCell>
                            <TableCell>{priceDetails.tooling}</TableCell>
                            <TableCell>{priceDetails.etest}</TableCell>
                            <TableCell>{totalPrice.toFixed(4)}</TableCell>
                            <TableCell>{m2Price.toFixed(4)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {/* Express Prices */}
              <Box
                sx={{
                  flex: "1",
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                  borderRadius: "5px",
                  p: 2,
                  pb: 4,
                  width: { xs: "100%", md: "48%" }, // Mobilde tam genişlik, masaüstünde 48%
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", mt: 2, ml: 2 }}
                >
                  Express Prices
                </Typography>

                <TableContainer elevation={0} sx={{ boxShadow: "none" }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Pieces</TableCell>
                        <TableCell>M²</TableCell>
                        <TableCell>Exp Lead Time</TableCell>
                        <TableCell>Exp Unit Price</TableCell>
                        <TableCell>Exp Tooling</TableCell>
                        <TableCell>Exp E-test</TableCell>
                        <TableCell>Exp Total</TableCell>
                        <TableCell>Exp M² Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(
                        rfqDetail.sales?.salesPrices?.[0]?.expressPrices || {}
                      ).map(([quantity, expPriceDetails]) => {
                        const area = calculatePanelArea(
                          rfqDetail.dimensions,
                          quantity
                        );
                        const totalExpressPrice =
                          expPriceDetails.expUnit * quantity +
                          expPriceDetails.expTooling +
                          expPriceDetails.expEtest;
                        const m2ExpressPrice = totalExpressPrice / area;

                        // Express lt değerini de acceptedOffer'dan alıyoruz.
                        const expLtValue =
                          rfqDetail.acceptedOffer?.expressPrices?.[quantity]
                            ?.expLt || expPriceDetails.expLt;

                        return (
                          <TableRow className="price-table" key={quantity}>
                            <TableCell>{quantity}</TableCell>
                            <TableCell>{area}</TableCell>
                            <TableCell>{expLtValue}</TableCell>
                            <TableCell>{expPriceDetails.expUnit}</TableCell>
                            <TableCell>{expPriceDetails.expTooling}</TableCell>
                            <TableCell>{expPriceDetails.expEtest}</TableCell>
                            <TableCell>
                              {totalExpressPrice.toFixed(4)}
                            </TableCell>
                            <TableCell>{m2ExpressPrice.toFixed(4)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to choose the offer from{" "}
            <strong>{selectedFactoryName}</strong>? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Manager Note Modal */}
      <Dialog
        open={openNoteModal}
        onClose={() => setOpenNoteModal(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "12px",
            padding: "12px",
          },
        }}
      >
        <DialogTitle
          sx={{
            borderBottom: "1px solid #e0e0e0",
            pb: 2,
            fontSize: "1.2rem",
            fontWeight: 600,
            color: "#333",
          }}
        >
          Re-quotation Request
        </DialogTitle>
        <DialogContent sx={{ mt: 2, minHeight: "200px" }}>
          <Typography variant="subtitle2" sx={{ mb: 2, color: "#666" }}>
            Please provide a note explaining why you are requesting a
            re-quotation from the factory.
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Manager Note"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={managerNote}
            onChange={(e) => setManagerNote(e.target.value)}
            required
            error={managerNote.trim() === ""}
            helperText={managerNote.trim() === "" ? "Note is required" : ""}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            borderTop: "1px solid #e0e0e0",
            pt: 2,
            px: 3,
            gap: 1,
          }}
        >
          <Button
            onClick={() => setOpenNoteModal(false)}
            variant="outlined"
            sx={{
              borderRadius: "8px",
              textTransform: "none",
              px: 3,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmitNote}
            variant="contained"
            color="primary"
            disabled={managerNote.trim() === ""}
            sx={{
              borderRadius: "8px",
              textTransform: "none",
              px: 3,
              color: "white!important",
            }}
          >
            Send to Purchase
          </Button>
        </DialogActions>
      </Dialog>

      {/* Drawer for editing offer */}
      <OfferEditDrawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        editingOffer={editingOffer}
        rfqDetail={rfqDetail}
        onValueChange={handleValueChange}
        onSaveChanges={handleSaveChanges}
      />

      {/* Silme onay dialogu */}
      <Dialog
        open={deleteFileDialogOpen}
        onClose={() => setDeleteFileDialogOpen(false)}
      >
        <DialogTitle>Delete File</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete "{selectedFileToDelete?.filename}"?
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteFileDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteFile} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RfqDetail;
