import React from "react";
import {
  Box,
  Typography,
  Divider,
  Grid,
  TextField,
  Button,
  Drawer,
  useTheme,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from "@mui/material";
import { getUserCredentials } from "../../utils/getUserCredentials";
import PersonIcon from "@mui/icons-material/Person";
import FactoryIcon from "@mui/icons-material/Factory";
import HistoryIcon from "@mui/icons-material/History";

const calculatePanelArea = (dimensions, quantity) => {
  if (
    !dimensions ||
    !dimensions.panelSizeX ||
    !dimensions.panelSizeY ||
    !dimensions.panelX ||
    !dimensions.panelY
  ) {
    return "Invalid dimensions";
  }

  const panelSizeX = Number(dimensions.panelSizeX);
  const panelSizeY = Number(dimensions.panelSizeY);
  const panelX = Number(dimensions.panelX);
  const panelY = Number(dimensions.panelY);

  const area =
    ((panelSizeX * panelSizeY) / (panelX * panelY) / 1000000) * quantity;

  return area.toFixed(2);
};

const OfferEditDrawer = ({
  open,
  onClose,
  editingOffer,
  rfqDetail,
  onValueChange,
  onSaveChanges,
}) => {
  const role = getUserCredentials().role;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  console.log(editingOffer);
  const calculateTotal = (quantity, priceDetails, isExpress = false) => {
    const unit =
      parseFloat(isExpress ? priceDetails.expUnit : priceDetails.unit) || 0;
    const tooling =
      parseFloat(isExpress ? priceDetails.expTooling : priceDetails.tooling) ||
      0;
    const etest =
      parseFloat(isExpress ? priceDetails.expEtest : priceDetails.etest) || 0;
    const quantityNum = parseFloat(quantity) || 0;

    return quantityNum * unit + tooling + etest;
  };

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h6">Edit Offer Details</Typography>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={2}>
          {/* Sol taraf - Mevcut fiyat düzenleme alanı */}
          <Grid item xs={12} md={isMobile ? 12 : 8}>
            {/* Normal fiyatlar için başlık */}
            <Grid item xs={12}>
              <Typography variant="h6">Prices</Typography>
              <Divider sx={{ my: 2 }} />
            </Grid>
            {/* Normal fiyatlar için input alanları */}
            {Object.entries(editingOffer.values || {}).map(
              ([quantity, details]) => (
                <Grid item key={quantity} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={1}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold" }}
                      >
                        {quantity}
                      </Typography>
                    </Grid>
                    <Grid item xs={1.5}>
                      <TextField
                        label="M²"
                        value={calculatePanelArea(
                          rfqDetail.dimensions,
                          quantity
                        )}
                        disabled={true}
                        margin="dense"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={1.5}>
                      <TextField
                        label="Lead Time"
                        value={details.lt || ""}
                        onChange={(e) =>
                          onValueChange(quantity, "lt", e.target.value, false)
                        }
                        disabled={role !== "factoryUser" && role !== "sales"}
                        margin="dense"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={1.5}>
                      <TextField
                        label="Unit Price"
                        value={details.unit || ""}
                        onChange={(e) =>
                          onValueChange(quantity, "unit", e.target.value, false)
                        }
                        margin="dense"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={1.5}>
                      <TextField
                        label="Tooling"
                        value={details.tooling || ""}
                        onChange={(e) =>
                          onValueChange(
                            quantity,
                            "tooling",
                            e.target.value,
                            false
                          )
                        }
                        margin="dense"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={1.5}>
                      <TextField
                        label="E-test"
                        value={details.etest || ""}
                        onChange={(e) =>
                          onValueChange(
                            quantity,
                            "etest",
                            e.target.value,
                            false
                          )
                        }
                        margin="dense"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={1.5}>
                      <TextField
                        label="Total"
                        disabled
                        value={calculateTotal(quantity, details)}
                        margin="dense"
                        fullWidth
                        size="small"
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={1.5}>
                      <TextField
                        label="M² Price"
                        disabled
                        value={(
                          calculateTotal(quantity, details) /
                          calculatePanelArea(rfqDetail.dimensions, quantity)
                        ).toFixed(4)}
                        margin="dense"
                        fullWidth
                        size="small"
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )
            )}

            {/* Express fiyatlar için input alanları */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Express Prices
              </Typography>
              <Divider sx={{ my: 2 }} />
            </Grid>

            {Object.entries(editingOffer.expressValues || {}).length > 0 ? (
              Object.entries(editingOffer.expressValues).map(
                ([quantity, details]) => (
                  <Grid item key={`express-${quantity}`} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "bold" }}
                        >
                          {quantity} (Express)
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          label="Exp Lead Time"
                          value={details.expLt || ""}
                          onChange={(e) =>
                            onValueChange(
                              quantity,
                              "expLt",
                              e.target.value,
                              true
                            )
                          }
                          disabled={role !== "factoryUser" && role !== "sales"}
                          margin="dense"
                          fullWidth
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          label="Exp Unit Price"
                          value={details.expUnit || ""}
                          onChange={(e) =>
                            onValueChange(
                              quantity,
                              "expUnit",
                              e.target.value,
                              true
                            )
                          }
                          margin="dense"
                          fullWidth
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          label="Exp Tooling"
                          value={details.expTooling || ""}
                          onChange={(e) =>
                            onValueChange(
                              quantity,
                              "expTooling",
                              e.target.value,
                              true
                            )
                          }
                          margin="dense"
                          fullWidth
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          label="Exp E-test"
                          value={details.expEtest || ""}
                          onChange={(e) =>
                            onValueChange(
                              quantity,
                              "expEtest",
                              e.target.value,
                              true
                            )
                          }
                          margin="dense"
                          fullWidth
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          label="Total"
                          disabled
                          value={calculateTotal(quantity, details, true)}
                          margin="dense"
                          fullWidth
                          size="small"
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              )
            ) : (
              <Typography variant="body2" color="textSecondary">
                No express prices available.
              </Typography>
            )}
          </Grid>

          {/* Sağ taraf - History verilerini gösteren alan */}
          {!isMobile && (
            <Grid item md={4}>
              <Typography
                variant="h6"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  "& .MuiSvgIcon-root": {
                    fontSize: "24px",
                  },
                }}
              >
                <HistoryIcon /> Price History
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  maxHeight: "calc(100vh - 300px)",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                {editingOffer.history?.map((item) => (
                  <Paper
                    key={item.revision}
                    elevation={0}
                    sx={{
                      p: 2,
                      border: "1px solid #e0e0e0",
                      borderRadius: "8px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mb: 2,
                        borderBottom: "1px solid #e0e0e0",
                        pb: 1,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "15px",
                          fontWeight: "600",
                          color: "#455a64",
                          letterSpacing: "0.3px",
                          textTransform: "none",
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          "& span": {
                            color: "#1976d2",
                            fontWeight: "700",
                          },
                        }}
                      >
                        Revision{" "}
                        <span style={{ color: "#ef7757" }}>
                          #{item.revision}
                        </span>
                      </Typography>
                      <Chip
                        icon={
                          item.userRole === "factoryUser" ? (
                            <FactoryIcon
                              sx={{ fontSize: "20px", color: "white" }}
                            />
                          ) : (
                            <PersonIcon
                              sx={{ fontSize: "20px", color: "white" }}
                            />
                          )
                        }
                        label={
                          item.userRole === "factoryUser"
                            ? item.factory.name
                            : "Purchase"
                        }
                        color={
                          item.userRole === "factoryUser"
                            ? "primary"
                            : "secondary"
                        }
                        size="small"
                        sx={{
                          "& .MuiChip-label": {
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "white",
                          },
                          "& .MuiChip-icon": {
                            color: "white",
                          },
                        }}
                      />
                    </Box>

                    <TableContainer>
                      <Table size="small" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Qty</TableCell>
                            <TableCell>Lead Time</TableCell>
                            <TableCell>Unit Price</TableCell>
                            <TableCell>Tooling</TableCell>
                            <TableCell>E-test</TableCell>
                            <TableCell>Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* Normal Prices */}
                          {Object.entries(item.prices || {}).map(
                            ([quantity, priceDetails]) => (
                              <TableRow
                                key={`${item.revision}-${quantity}`}
                                sx={{
                                  backgroundColor: "#f5f5f5",
                                  "&:hover": { backgroundColor: "#eeeeee" },
                                }}
                              >
                                <TableCell sx={{ fontWeight: "bold" }}>
                                  {quantity}
                                </TableCell>
                                <TableCell>{priceDetails.lt || 0}</TableCell>
                                <TableCell>{priceDetails.unit || 0}</TableCell>
                                <TableCell>
                                  {priceDetails.tooling || 0}
                                </TableCell>
                                <TableCell>{priceDetails.etest || 0}</TableCell>
                                <TableCell>
                                  {calculateTotal(quantity, priceDetails)}
                                </TableCell>
                              </TableRow>
                            )
                          )}

                          {/* Express Prices */}
                          {Object.entries(item.expressPrices || {}).map(
                            ([quantity, expPriceDetails]) => (
                              <TableRow
                                key={`${item.revision}-exp-${quantity}`}
                                sx={{
                                  backgroundColor: "#fff3e0",
                                  "&:hover": { backgroundColor: "#ffe0b2" },
                                }}
                              >
                                <TableCell sx={{ fontWeight: "bold" }}>
                                  <Chip
                                    label={`${quantity} (Exp)`}
                                    size="small"
                                    color="warning"
                                    variant="filled"
                                    sx={{
                                      "& .MuiChip-label": {
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        color: "white",
                                      },
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  {expPriceDetails.expLt || 0}
                                </TableCell>
                                <TableCell>
                                  {expPriceDetails.expUnit || 0}
                                </TableCell>
                                <TableCell>
                                  {expPriceDetails.expTooling || 0}
                                </TableCell>
                                <TableCell>
                                  {expPriceDetails.expEtest || 0}
                                </TableCell>
                                <TableCell>
                                  {calculateTotal(
                                    quantity,
                                    expPriceDetails,
                                    true
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                ))}
              </Box>
            </Grid>
          )}
        </Grid>

        <Button
          variant="contained"
          color="primary"
          onClick={onSaveChanges}
          sx={{ mt: 2, color: "white!important" }}
        >
          Re Quotation
        </Button>
      </Box>
    </Drawer>
  );
};

export default OfferEditDrawer;
