import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { acceptOffer, reviseOffer } from "../../Api/Rfq";
import { useNotification } from "../../context/NotificationContext";

const calculatePanelArea = (dimensions, quantity) => {
  if (
    !dimensions ||
    !dimensions.panelSizeX ||
    !dimensions.panelSizeY ||
    !dimensions.panelX ||
    !dimensions.panelY
  ) {
    return "Invalid dimensions";
  }

  const panelSizeX = Number(dimensions.panelSizeX);
  const panelSizeY = Number(dimensions.panelSizeY);
  const panelX = Number(dimensions.panelX);
  const panelY = Number(dimensions.panelY);

  const area =
    ((panelSizeX * panelSizeY) / (panelX * panelY) / 1000000) * quantity;

  return area.toFixed(2);
};

const FactoryUserInput = ({
  rfq,
  rfqId,
  factoryUserId,
  initialPrices = {},
  initialExpressPrices = {},
  fetchRfqDetail,
}) => {
  const navigate = useNavigate();
  const [localPrices, setLocalPrices] = useState(initialPrices);
  const [localExpressPrices, setLocalExpressPrices] =
    useState(initialExpressPrices);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // Editing mode state
  const { showNotification } = useNotification();
  console.log(rfq);
  useEffect(() => {
    setLocalPrices(initialPrices);
    setLocalExpressPrices(initialExpressPrices);
    const offerStatus = rfq?.offers?.[0]?.status === "submitted";
    const isAccepted = rfq?.offers?.[0]?.status === "accepted";
    setIsSubmitted(offerStatus);
    setIsAccepted(isAccepted);
    setIsEditing(rfq.generalInfo?.status === "draft" ? true : false);
  }, [initialPrices, initialExpressPrices, rfq]);

  const handleInputChange = (quantity, field, value, isExpress = false) => {
    if (isExpress) {
      const updatedExpressPrices = {
        ...localExpressPrices,
        [quantity]: {
          ...localExpressPrices[quantity],
          [field]: value,
        },
      };
      setLocalExpressPrices(updatedExpressPrices);
    } else {
      const updatedPrices = {
        ...localPrices,
        [quantity]: {
          ...localPrices[quantity],
          [field]: value,
        },
      };
      setLocalPrices(updatedPrices);
    }
  };

  const calculateTotal = (quantity, priceDetails, isExpress = false) => {
    const unit =
      parseFloat(isExpress ? priceDetails.expUnit : priceDetails.unit) || 0;
    const tooling =
      parseFloat(isExpress ? priceDetails.expTooling : priceDetails.tooling) ||
      0;
    const etest =
      parseFloat(isExpress ? priceDetails.expEtest : priceDetails.etest) || 0;
    const quantityNum = parseFloat(quantity) || 0;

    return quantityNum * unit + tooling + etest;
  };

  const handleSubmitOffer = async () => {
    setLoading(true);
    try {
      const offerId = rfq?.offers?.[0]?._id;
      const prices = localPrices;
      const expressPrices = localExpressPrices;

      if (!offerId) {
        showNotification("Offer data is missing!", "error");
        setLoading(false);
        return;
      }

      await reviseOffer(factoryUserId, {
        offerId,
        prices,
        expressPrices,
      });

      showNotification("Offer submitted successfully!", "success");
      await fetchRfqDetail();
      setIsEditing(false);
      navigate("/rfqs");
    } catch (error) {
      showNotification("Failed to submit offer!", "error");
      console.error("Error submitting offer:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAcceptOffer = async () => {
    setLoading(true);
    try {
      const offerId = rfq?.offers?.[0]?._id;

      if (!offerId) {
        showNotification("Offer data is missing!", "error");
        setLoading(false);
        return;
      }

      await acceptOffer(rfqId, offerId);
      showNotification("Offer accepted successfully!", "success");
      await fetchRfqDetail();
    } catch (error) {
      showNotification("Failed to accept offer!", "error");
      console.error("Error accepting offer:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleReQuotation = () => {
    setIsEditing(true); // Enable editing mode
  };

  const handleCancel = () => {
    setIsEditing(false); // Disable editing mode
    setLocalPrices(initialPrices);
    setLocalExpressPrices(initialExpressPrices);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        <Box
          sx={{
            backgroundColor: "#ef7757",
            width: "100%",
            padding: "10px 20px",
            display: "flex",
            pt: 2,
            borderRadius: "5px",
            alignItems: "center",
          }}
        >
          <Typography
            gutterBottom
            sx={{
              fontWeight: "600",
              fontSize: "1.2rem",
              color: "white!important",
            }}
          >
            Enter Your Offer Details
          </Typography>
        </Box>
      </Typography>

      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
          mt: 1,
          width: "100%!important",
          ml: 0,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Normal Prices
        </Typography>
        {Object.entries(localPrices).map(([quantity, priceDetails]) => (
          <Grid item xs={12} key={quantity}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={1}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", textAlign: "left" }}
                >
                  Quantity: {quantity}
                </Typography>
              </Grid>
              <Grid item xs={12} md={11}>
                <Grid container spacing={2}>
                  <Grid item xs={1.5}>
                    <TextField
                      label="Lead Time"
                      value={priceDetails.lt ?? ""}
                      onChange={(e) =>
                        handleInputChange(quantity, "lt", e.target.value, false)
                      }
                      fullWidth
                      size="small"
                      margin="dense"
                      disabled={!isEditing || loading}
                    />
                  </Grid>
                  <Grid item xs={1.5}>
                    <TextField
                      label="M²"
                      value={calculatePanelArea(rfq.dimensions, quantity)}
                      disabled
                      margin="dense"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={1.5}>
                    <TextField
                      label="Unit Price"
                      value={priceDetails.unit ?? ""}
                      onChange={(e) =>
                        handleInputChange(
                          quantity,
                          "unit",
                          e.target.value,
                          false
                        )
                      }
                      fullWidth
                      size="small"
                      margin="dense"
                      disabled={!isEditing || loading}
                    />
                  </Grid>
                  <Grid item xs={1.5}>
                    <TextField
                      label="Tooling"
                      value={priceDetails.tooling ?? ""}
                      onChange={(e) =>
                        handleInputChange(
                          quantity,
                          "tooling",
                          e.target.value,
                          false
                        )
                      }
                      fullWidth
                      size="small"
                      margin="dense"
                      disabled={!isEditing || loading}
                    />
                  </Grid>
                  <Grid item xs={1.5}>
                    <TextField
                      label="E-test"
                      value={priceDetails.etest ?? ""}
                      onChange={(e) =>
                        handleInputChange(
                          quantity,
                          "etest",
                          e.target.value,
                          false
                        )
                      }
                      fullWidth
                      size="small"
                      margin="dense"
                      disabled={!isEditing || loading}
                    />
                  </Grid>
                  <Grid item xs={1.5}>
                    <TextField
                      label="M² Price"
                      disabled
                      value={(
                        calculateTotal(quantity, priceDetails) /
                        calculatePanelArea(rfq.dimensions, quantity)
                      ).toFixed(4)}
                      margin="dense"
                      fullWidth
                      size="small"
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Total Price"
                      value={calculateTotal(quantity, priceDetails)}
                      fullWidth
                      size="small"
                      margin="dense"
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          Express Prices
        </Typography>
        {Object.entries(localExpressPrices).map(
          ([quantity, expPriceDetails]) => (
            <Grid item xs={12} key={quantity}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={1}>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", textAlign: "left" }}
                  >
                    Quantity: {quantity}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={11}>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <TextField
                        label="Exp Lead Time"
                        value={expPriceDetails.expLt ?? ""}
                        onChange={(e) =>
                          handleInputChange(
                            quantity,
                            "expLt",
                            e.target.value,
                            true
                          )
                        }
                        fullWidth
                        size="small"
                        margin="dense"
                        disabled={!isEditing || loading}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        label="Exp Unit Price"
                        value={expPriceDetails.expUnit ?? ""}
                        onChange={(e) =>
                          handleInputChange(
                            quantity,
                            "expUnit",
                            e.target.value,
                            true
                          )
                        }
                        fullWidth
                        size="small"
                        margin="dense"
                        disabled={!isEditing || loading}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        label="Exp Tooling"
                        value={expPriceDetails.expTooling ?? ""}
                        onChange={(e) =>
                          handleInputChange(
                            quantity,
                            "expTooling",
                            e.target.value,
                            true
                          )
                        }
                        fullWidth
                        size="small"
                        margin="dense"
                        disabled={!isEditing || loading}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        label="Exp E-test"
                        value={expPriceDetails.expEtest ?? ""}
                        onChange={(e) =>
                          handleInputChange(
                            quantity,
                            "expEtest",
                            e.target.value,
                            true
                          )
                        }
                        fullWidth
                        size="small"
                        margin="dense"
                        disabled={!isEditing || loading}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Total Exp Price"
                        value={calculateTotal(quantity, expPriceDetails, true)}
                        fullWidth
                        size="small"
                        margin="dense"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
        )}
      </Grid>

      {!isSubmitted && !isAccepted && !isEditing && (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleReQuotation}
          sx={{ mt: 2, mr: 2, color: "white!important" }}
          disabled={loading}
          size="small"
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : rfq?.offers?.[0]?.status === "assigned_to_factory" ? (
            "Submit Quotation"
          ) : (
            "Re Quotation"
          )}
        </Button>
      )}
      {isEditing ? (
        <>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmitOffer}
            sx={{ mt: 2, mr: 2, color: "white!important" }}
            disabled={loading}
            size="small"
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Send Quotation"
            )}
          </Button>
          <Button
            variant="contained"
            onClick={handleCancel}
            sx={{ mt: 2, color: "white!important", backgroundColor: "#b0b0b0" }}
            disabled={loading}
            size="small"
          >
            Cancel
          </Button>
        </>
      ) : (
        !isSubmitted &&
        !isAccepted &&
        rfq.generalInfo?.status !== "draft" &&
        rfq.offers?.[0]?.status !== "assigned_to_factory" && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleAcceptOffer}
            sx={{ mt: 2, color: "white!important" }}
            disabled={loading}
            size="small"
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Accept Target Price"
            )}
          </Button>
        )
      )}
    </Box>
  );
};

export default FactoryUserInput;
